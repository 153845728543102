import React from 'react'
import Section from '../components/common/Section'
import FileDownloader from '../components/FileDownloader'
import Layout from '../components/Layout'

const ArchivosPrivados = props => (
  <Layout color="color">
    <Section>
      <div
        style={{
          maxWidth: '700px',
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '1.4rem',
          minHeight: '100vh',
        }}
      >
        <p style={{ textAlign: 'justify' }}>
          Para la comodidad de todos nuestros clientes ponemos a su disposición
          estos archivos, da click en descargar e ingresa el codigo
          proporcionado, si aun no lo tienes solicitalo a{' '}
          <a
            href="mailto:luisjavier@ascg.mx"
            style={{ textDecoration: 'none', color: '#00BEF0' }}
          >
            AS Consulting Group
          </a>
          .
        </p>

        <FileDownloader {...props} />
      </div>
    </Section>
  </Layout>
)

export default ArchivosPrivados
