import React, { Component } from 'react'
import qs from 'query-string'
import axios from 'axios'
import FileSaver from 'file-saver'
import FormFields from '../common/FormFields'

import { createNotification } from '../../utils/createNotification'
import { downloadSchema, b64toBlob } from './utils'

class FileDownloader extends Component {
  state = {
    loading: false,
  }
  handleSubmit = ({ code }) => {
    let notificationData = {
      type: 'success',
      title: 'Archivos descargados correctamente!',
      message: 'Gracias por confiar en nostros!',
    }
    const { file } = qs.parse(this.props.location.search)
    this.setState({ loading: true }, async () => {
      try {
        const {
          data: { body, contentType },
        } = await axios({
          method: 'post',
          url: '/ASCGDowndloadPrivateFiles',
          data: {
            file,
          },
          baseURL:
            'https://x9qeebwa74.execute-api.us-east-1.amazonaws.com/default/',
          auth: {
            username: code,
            password: '',
          },
        })
        const blob = b64toBlob(body, contentType, 512)
        FileSaver.saveAs(blob, 'ascg_formatos.zip')
      } catch (err) {
        notificationData = {
          type: 'error',
          title: 'No intente hackearnos!!',
          message: 'Intentelo de nuevo',
        }
      }

      this.setState({ loading: false }, () => {
        const { type, ...restData } = notificationData
        createNotification(type, { ...restData })
      })
    })
  }
  render() {
    return (
      <FormFields
        submit={this.handleSubmit}
        fields={downloadSchema}
        buttonText={!this.state.loading ? 'button_download' : 'button_loading'}
        color="blue"
      />
    )
  }
}

export default FileDownloader
